import { sprig } from '@sprig-technologies/sprig-browser'
import { secrets } from '@chilipiper/config'
import { Session } from '@chilipiper/api-type-def'

export const Sprig = sprig.configure({
  environmentId: secrets.sprigEnvironmentId,
})

export const setSprigUser = ({ id, email }: Session) => {
  id && Sprig('setUserId', id)
  email && Sprig('setAttribute', 'email', email)

  if (email?.includes('@chilipiper.')) {
    Sprig('setAttribute', 'isPiper', 'true')
  }
}
