import React from 'react'
import { WaitingComponent } from '@chilipiper/meetings-components/src/waiting-component'
import { Flex } from '@chilipiper/design-system'
import { Loader } from '@chilipiper/design-system/src/new'
import { lazyLoading } from './utils/lazyLoading'

const AdminRouting = lazyLoading(() => import('./AdminRouting'))

export const AdminRouter = WaitingComponent(
  AdminRouting,
  <Flex justifyContent='center' alignItems='center' h='100%'>
    <Loader />
  </Flex>
)
