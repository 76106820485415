import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: process.env.GTM_ID,
}

export const initialize = () => {
  if (!tagManagerArgs.gtmId) {
    throw Error('You have to specify GTM_ID to use Tag Manager')
  }
  TagManager.initialize({
    gtmId: tagManagerArgs.gtmId,
  })
}
