export const getOrigin = () => {
  if (typeof window === 'undefined') {
    return ''
  }
  if (process.env.IS_STORYBOOK) {
    return 'https://calendar.chilipiper.io'
  }
  return window.location.origin
}

export const getDomain = () => {
  if (typeof window === 'undefined') {
    return ''
  }
  if (process.env.IS_STORYBOOK) {
    return 'https://calendar.chilipiper.io'
  }
  const params = new URLSearchParams(window.location.search)
  const result = params.get('domain') || window.location.host.split(/[.:]/).shift()
  return result as string
}

export const parseQuery = (search?: string) => {
  if (typeof window === 'undefined') {
    return {}
  }
  const str = search || window.location.hash.split('?')[1] || window.location.search
  return Object.fromEntries(new URLSearchParams(str).entries())
}

export const stringifyQuery = (query: Record<string, string | undefined>) => {
  const queryWithoutUndefined = Object.fromEntries(
    Object.entries(query).filter(([, value]) => value !== undefined)
  ) as Record<string, string>
  return `?${new URLSearchParams(queryWithoutUndefined).toString()}`
}
