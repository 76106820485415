import { lazy, ComponentType } from 'react'

type DefaultComponentPromise = Promise<{
  default: ComponentType<any>
}>

export const lazyLoading = (fn: () => DefaultComponentPromise) =>
  lazy(
    () =>
      fn().catch(error => {
        console.error(`Failed to lazy load, reloading. Error: ${error}`)
        window.location.reload()
      }) as DefaultComponentPromise
  )
