import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { endpoint } from '@chilipiper/config'
import { rolloutInit, setRolloutUser } from '@chilipiper/rollout'
import { session as getSession, put, setGlobalJwtApi } from '@chilipiper/service/src/api'
import { initialize as initGTM } from '@chilipiper/service/src/gtm'
import { jwtApiV2WithV1Fallback } from '@chilipiper/service/src/jwtApiV2WithV1Fallback'
import { Provider as ThemeProvider, initializeModal } from '@chilipiper/design-system'
import { parseQuery } from '@chilipiper/utils'
import { loadCustomCss } from '@chilipiper/service/src/inject-css'
import { getSubdomain, maybeCanaryRedirect } from '@chilipiper/service/src/canary'
import { initTracking, setTrackingUserId } from '@chilipiper/service/src/tracking'
import { logoutV2 } from '@chilipiper/service/src/jwtApiV2'
import { jwtApiV1 } from '@chilipiper/service/src/jwtApiV1'
import { AdminRouter } from './app/Routing'
import { updateLogo } from './app/utils/updateLogo'
import { setSprigUser } from './app/utils/Sprig'
import { lazyLoading } from './app/utils/lazyLoading'

import 'react-dates/lib/css/_datepicker.css'
import './app/styles-deprecated/style.postcss'
import './app/styles-deprecated/actionbar.postcss'
import './app/components/components.postcss'
import './app/components/expandable-block.postcss'
import './app/routers/settings/salesforce.postcss'
import './app/queues/queues.postcss'
import '@chilipiper/design-system/css/reset.css'
import './legacyGlobal.scss'

// eslint-disable-next-line no-console
console.log('homebase version', __APP_VERSION__) // "1.0.0"
const Login = lazyLoading(() => import('app/login/Login'))
const Calendar = lazyLoading(() => import('app/calendar/Calendar'))
const DownloadExtension = lazyLoading(() => import('app/download-extension/DownloadExtension'))
const SignedUpMobile = lazyLoading(() => import('app/signed-up-mobile/SignedUpMobile'))
const NoAccount = lazyLoading(() => import('app/NoAccount'))
const Integration = lazyLoading(() => import('app/Integration'))
const CRM = lazyLoading(() => import('app/Crm'))
const Google = lazyLoading(() => import('app/Google'))
const GoogleSSO = lazyLoading(() => import('app/GoogleSSO'))
const ErrorViewApp = lazyLoading(() => import('app/ErrorView'))
const Signup = lazyLoading(() => import('app/signed-up/Signup'))
const BookDemo = lazyLoading(() => import('app/book-demo/BookDemo'))
const LoginSso = lazyLoading(() => import('app/login-sso/LoginSso'))

const { href } = window.location

const isLogin = href.indexOf('/login') !== -1
const isHomebase = href.indexOf('index.html') !== -1 || href.indexOf('.html') === -1

initTracking()
setGlobalJwtApi(jwtApiV2WithV1Fallback)

if (process.env.NODE_ENV !== 'development') {
  initGTM()
}
loadCustomCss()

const queryClient = new QueryClient()

const mountRouting = () => {
  initializeModal()
  const container = document.getElementById('root')

  if (container) {
    createRoot(container).render(
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AdminRouter />
        </ThemeProvider>
      </QueryClientProvider>
    )
  }
}

const error = e => {
  console.error(e)
  if (!isLogin) {
    const parameters = parseQuery() || {}
    const referral = parameters.referral ? `?referral=${parameters.referral}&` : '?'
    maybeCanaryRedirect(getSubdomain()).then(redirected => {
      // 2 simultaneous redirects may have conflict
      if (redirected === true) {
        return
      }
      window.location.href = `${endpoint.homebase()}/login.html${referral}`
    })
  } else {
    mountRouting()
  }
}

const renderLoader = () => {
  return (
    <div className='routing-loader'>
      <div className='routing-loader-body'>
        <span>Please wait</span>
        <div className='fabe-book-loading' />
      </div>
    </div>
  )
}

const boot = (app, mountTo) => {
  const container = document.getElementById(mountTo)

  if (container) {
    createRoot(container).render(
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <Suspense fallback={renderLoader()}>{React.createElement(app)}</Suspense>
        </ThemeProvider>
      </QueryClientProvider>
    )
  }
}

const mountViews = async () => {
  try {
    const sessionResponse = await getSession()
    const redirectSubdomain = sessionResponse?.subDomain || getSubdomain()
    maybeCanaryRedirect(redirectSubdomain)
  } catch (err) {
    // CP-7995
    maybeCanaryRedirect(getSubdomain())
  } finally {
    boot(Login, 'login')
    boot(LoginSso, 'login-sso')
    boot(CRM, 'crm-account')
    boot(Google, 'google')
    boot(GoogleSSO, 'google-sso')
    boot(NoAccount, 'no-account')
    boot(Calendar, 'calendar')
    boot(Integration, 'integration')
    boot(ErrorViewApp, 'error')
    boot(Signup, 'signup')
    boot(DownloadExtension, 'download-extension')
    boot(DownloadExtension, 'setup-onboarding')
    boot(SignedUpMobile, 'mobile')
    boot(BookDemo, 'book-demo')
  }
}

const init = async () => {
  rolloutInit('homebase')
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const sessionId = urlParams.get('sid')
  const initialFetchTokenCode = urlParams.get('code')
  if (sessionId) {
    await put(`set-session/${sessionId}`, {})
  }
  try {
    let accessToken
    if (initialFetchTokenCode) {
      await logoutV2()
      accessToken = await jwtApiV1.login(initialFetchTokenCode)
    } else {
      accessToken = await jwtApiV2WithV1Fallback.login(initialFetchTokenCode)
    }
    if (window.Office) {
      Office.context.ui.messageParent(JSON.stringify({ accessToken }), {
        targetOrigin: '*',
      })
    }
  } catch (err) {
    console.error(err)
  }
  try {
    if (isHomebase) {
      const session = await getSession(true)
      setRolloutUser(session)
      setSprigUser(session)
      setTrackingUserId(session.id)
      mountRouting()
    }
  } catch (err) {
    error()
  } finally {
    mountViews()
    updateLogo()
  }
}

init()
