export function updateLogo() {
  const today = new Date()
  const start = new Date()
  const end = new Date()
  const holidays = [
    {
      startDate: 3,
      endDate: 6,
      startMonth: 4,
      endMonth: 4,
      imagePath: 'holiday-logos/logo-sinco-de-mayo_svg.svg',
      faviconPath: 'holiday-logos/logo-sinco-de-mayo.png',
      backgroundSize: 'contain',
    },
    {
      startDate: 24,
      endDate: 27,
      startMonth: 4,
      endMonth: 4,
      imagePath: 'holiday-logos/logo-memorial-day_svg.svg',
      faviconPath: 'holiday-logos/logo-memorial-day.png',
      backgroundSize: 'contain',
    },
    {
      startDate: 1,
      endDate: 1,
      startMonth: 5,
      endMonth: 6,
      imagePath: 'holiday-logos/logo-pride-month_svg.svg',
      faviconPath: 'holiday-logos/logo-pride-month.png',
      backgroundSize: 'contain',
    },
    {
      startDate: 3,
      endDate: 4,
      startMonth: 6,
      endMonth: 6,
      imagePath: 'holiday-logos/logo-memorial-day_svg.svg',
      faviconPath: 'holiday-logos/logo-memorial-day.png',
      backgroundSize: 'contain',
    },
    {
      startDate: 24,
      endDate: 25,
      startMonth: 11,
      endMonth: 11,
      imagePath: 'chili/ny-chilli.gif',
      faviconPath: 'chili/ny-chilli.gif',
      backgroundSize: 'cover',
    },
    {
      startDate: 16,
      endDate: 22,
      startMonth: 10,
      endMonth: 10,
      imagePath: 'chili/transgender.svg',
      faviconPath: 'chili/transgender.png',
      backgroundSize: 'contain',
    },
  ]
  holidays.forEach(holiday => {
    start.setMonth(holiday.startMonth)
    start.setDate(holiday.startDate)

    end.setMonth(holiday.endMonth)
    end.setDate(holiday.endDate)
    if (today >= start && today <= end) {
      const { styleSheets } = document
      const sheet = styleSheets[styleSheets.length - 2]
      try {
        const rulesLength = sheet.cssRules.length
        const backgroundSize = holiday.backgroundSize || 'unset'
        sheet.insertRule(
          `.site-logo { background-image: url(/images/${holiday.imagePath}) !important; width: 40px; background-size: ${backgroundSize} !important; background-repeat: no-repeat; }

                        `,
          rulesLength
        )
        sheet.insertRule(
          `.homebase-site-logo { background-image: url(/images/${holiday.imagePath}) !important; width: 25px; height: 25px; background-size: ${backgroundSize} !important; background-repeat: no-repeat; }
                        `,
          rulesLength
        )
        sheet.insertRule(`.homebase-site-logo svg { display: none !important; }`, rulesLength)
        sheet.insertRule(
          `.fabe-footer-chili{ background-image: url(/images/${holiday.imagePath}) !important; background-size: cover !important; }`,
          rulesLength
        )
        sheet.insertRule(
          `.fabe-chili-ownership-icon{ background-image: url(/images/${holiday.imagePath}) !important; background-size: cover !important; }`,
          rulesLength
        )
        // eslint-disable-next-line no-empty
      } catch (error) {}
      const links = document.querySelectorAll('link[rel*="icon"]')
      if (links.length) {
        for (let i = 0; i < links.length; i += 1) {
          if (links[i].href.includes('png')) {
            links[i].href = `${window.location.origin}/images/${holiday.faviconPath}`
          } else {
            links[i].href = `${window.location.origin}/images/${holiday.imagePath}`
          }
        }
      } else {
        const link = document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = `${window.location.origin}/images/${holidays.faviconPath}`
        document.getElementsByTagName('head')[0].appendChild(link)
      }
    }
  })
}
