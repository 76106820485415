import { get } from './api'

const getParamValue = (paramName: string) => {
  const regex = new RegExp(`${paramName}=([^&]*)`)
  const matchRegex = window.location.search.match(regex)
  return matchRegex?.[1]
}

const log = (error: string) => {
  console.error('[chilipiper] failed to load css', error)
}

export const injectCss = (css?: string) => {
  if (!css) {
    return
  }
  const sheet = document.createElement('style')
  sheet.innerHTML = css
  document.body.appendChild(sheet)
}

export const loadCustomCss = () => {
  const routeId = getParamValue('routeId')
  const domain = getParamValue('domain')

  if (routeId && domain) {
    get(`custom-css/${domain}/${routeId}`)
      .json<{ css?: string; error?: string }>()
      .then(response => {
        if (response?.error) {
          log(response.error)
        } else {
          injectCss(response.css)
        }
      })
      .catch(log)
  }
}
