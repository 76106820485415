import {
  enableLinkClickTracking,
  LinkClickTrackingPlugin,
} from '@snowplow/browser-plugin-link-click-tracking'
import {
  setUserId as setSnowplowUserId,
  trackPageView as snowplowTrackPageView,
  newTracker,
  enableActivityTracking,
  trackSelfDescribingEvent,
  CommonEventProperties,
  SelfDescribingEvent,
} from '@snowplow/browser-tracker'
import { refreshLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking'
import { secrets } from '@chilipiper/config'

export { trackSelfDescribingEvent } from '@snowplow/browser-tracker'

export const initTracking = (appId = 'app') => {
  newTracker('spTracker', secrets.snowplowEndpoint, {
    appId,
    plugins: [LinkClickTrackingPlugin()],
    stateStorageStrategy: 'cookieAndLocalStorage',
    discoverRootDomain: true,
    cookieSameSite: 'None',
    cookieSecure: true,
  })
  enableLinkClickTracking()
  enableActivityTracking({
    minimumVisitLength: 30,
    heartbeatDelay: 10,
  })
}

export const trackPageView = () => {
  snowplowTrackPageView()
  refreshLinkClickTracking()
}

export const setTrackingUserId = (userId: string) => {
  setSnowplowUserId(userId)
}

export const trackSchemedEvent = (event: SelfDescribingEvent & CommonEventProperties) => {
  trackSelfDescribingEvent(event)
}
